import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e623a3ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-container" }
const _hoisted_2 = { class: "mask" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = {
  key: 0,
  class: "el-upload__tip"
}
const _hoisted_5 = { style: {"display":"inline-block"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ImgBox = _resolveComponent("ImgBox")!
  const _component_zoom_in = _resolveComponent("zoom-in")!
  const _component_delete_filled = _resolveComponent("delete-filled")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_upload, {
      "show-file-list": false,
      action: _ctx.action,
      headers: _ctx.headers,
      accept: "image/*",
      "before-upload": _ctx.beforeUpload,
      "on-progress": _ctx.onProgress,
      "on-success": _ctx.onSuccess,
      "on-error": _ctx.onError,
      drag: ""
    }, {
      default: _withCtx(() => [
        (!_ctx.url)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "image-slot",
              style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`)
            }, [
              (_ctx.placeholder)
                ? (_openBlock(), _createBlock(_component_el_image, {
                    key: 0,
                    src: _ctx.placeholder,
                    fit: "fill",
                    style: `width:100%;height:100%;`
                  }, null, 8, ["src"]))
                : (_openBlock(), _createBlock(_component_el_icon, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_plus)
                    ]),
                    _: 1
                  }))
            ], 4))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "image",
              style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`)
            }, [
              _createVNode(_component_ImgBox, {
                ref: "imgBoxRef",
                fit: "contain",
                src: _ctx.url,
                width: _ctx.width,
                height: _ctx.height,
                ifAnimation: false
              }, null, 8, ["src", "width", "height"]),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", {
                    title: "预览",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onShowImg && _ctx.onShowImg(...args)), ["stop"]))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_zoom_in)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("span", {
                    title: "移除",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)), ["stop"]))
                  }, [
                    _createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_delete_filled)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ], 4)),
        _withDirectives(_createElementVNode("div", {
          class: "progress",
          style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`)
        }, [
          _createVNode(_component_el_image, {
            src: _ctx.progress.preview,
            style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`),
            fit: "fill"
          }, null, 8, ["src", "style"]),
          _createVNode(_component_el_progress, {
            type: "circle",
            width: Math.min(_ctx.width, _ctx.height) * 0.8,
            percentage: _ctx.progress.percent
          }, null, 8, ["width", "percentage"])
        ], 4), [
          [_vShow, _ctx.progress.percent]
        ])
      ]),
      _: 1
    }, 8, ["action", "headers", "before-upload", "on-progress", "on-success", "on-error"]),
    (!_ctx.notip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_alert, {
              title: 
            _ctx.notipTitle ? _ctx.notipTitle : `上传图片支持 ${_ctx.ext.join(' / ')} 格式，且图片大小不超过 ${_ctx.size}MB，建议图片尺寸为 ${_ctx.width}*${_ctx.height}`
          ,
              type: "info",
              "show-icon": "",
              closable: false
            }, null, 8, ["title"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}