
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      setTimeout(() => {
        const loadingRef = window.document.getElementById('Loading')
        if (loadingRef) {
          loadingRef.remove()
        }
      }, 666)
    })

    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
})
