
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Logo',
  props: {
    showLogo: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    // onBeforeMount(() => {})

    const data = reactive({
      title: '考拉项目管理系统',
      logo: require('@/assets/images/logo.png'),

      onGoHome () {
        if (store.state.settings.enableDashboard) {
          router.push('/')
        }
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
