
import { defineComponent, computed, reactive, toRefs } from 'vue'

import path from 'path'
import Item from './Item.vue'
import AppLink from './Link.vue'

export default defineComponent({
  name: 'SidebarItem',
  components: {
    Item,
    AppLink
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const data = reactive({
      hasChildren: computed(() => {
        let flag = true
        if (props.item.children) {
          if (props.item.children.every((item: any) => item.meta.sidebar === false)) {
            flag = false
          }
        } else {
          flag = false
        }
        return flag
      }),

      isExternal (path: any) {
        return /^(https?:|mailto:|tel:)/.test(path)
      },
      resolvePath (routePath: any) {
        if (data.isExternal(routePath)) {
          return routePath
        }
        if (data.isExternal(props.basePath)) {
          return props.basePath
        }
        return path.resolve(props.basePath, routePath)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
