/*
 * @Description: 角色管理-role
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  name: string
}
// 保存
interface saveQuery {
  id?: number
  name: string
  power_ids: number[]
}
// 详情、删除
interface queryId {
  id: number
}
// 无参数
interface noParameter {
  [key: string]: any
}

/* 接口定义 */
export class roleApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/role/index',
      data
    })
  }

  // 保存
  static save (data: saveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/role/save',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/role/info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/role/del',
      data
    })
  }

  // 权限列表
  static getPowerList (data: noParameter): Promise<HttpResponse> {
    return http({
      url: '/api/admin/role/power_index',
      data
    })
  }
}
