
import { computed, defineComponent, reactive, toRefs, nextTick, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import variables from '@/assets/styles/resources/variables.scss'
import Logo from './components/Logo/index.vue'
import UserMenu from './components/UserMenu/index.vue'
import SidebarItem from './components/SidebarItem/index.vue'
import Topbar from './components/Topbar/index.vue'
import TagsView from './components/TagsView/index.vue'

export default defineComponent({
  name: 'Layout',
  components: {
    Logo,
    SidebarItem,
    Topbar,
    UserMenu,
    TagsView
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onMounted(() => {
      window.addEventListener('scroll', data.onScroll)
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', data.onScroll)
    })

    const data = reactive({
      isRouterAlive: true,
      routePath: '',
      sidebarScrollTop: 0,
      scrollTop: 0,

      variables: computed((): any => {
        return variables
      }),
      realSidebarWidth: computed((): any => {
        let realSidebarWidth = 0
        if (!store.state.settings.showHeader && store.state.menu.routes.length > 1) {
          realSidebarWidth = parseInt(variables.g_main_sidebar_width)
        }
        if (store.state.settings.sidebarCollapse) {
          realSidebarWidth += 64
        } else {
          realSidebarWidth += parseInt(variables.g_sub_sidebar_width)
        }

        return `${realSidebarWidth}px`
      }),

      onSidebarScroll (e: any) {
        data.sidebarScrollTop = e.target.scrollTop
      },
      onScroll () {
        data.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
