
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

import { ElNotification } from 'element-plus'

export default defineComponent({
  name: 'UserMenu',
  setup () {
    const store = useStore()

    const data = reactive({
      info: JSON.parse(localStorage.info),
      isFullscreen: false,

      async handleCommand (command: any) {
        switch (command) {
          case 'logout':
            await store.dispatch('user/logout', 'server')
            ElNotification.success({
              title: '提示',
              message: '已退出登录',
              duration: 2000
            })
            break
        }
      }
    })

    const errorHandler = () => true

    return {
      ...toRefs(data),
      errorHandler
    }
  }
})
