/*
 * @Description: 接口示例-index
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// POST请求
interface getListQuery {
  page: number
  paginate: number
  name: string
}
// 无参数
interface noParameter {
  [key: string]: any
}

/* 接口定义 */
export class indexApi {
  // POST请求
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/order/refund_reason_index',
      data
    })
  }

  // GET请求
  static getInfo (params: noParameter): Promise<HttpResponse> {
    return http({
      /* 直接覆盖baseURL */
      baseURL: 'https://www.fastmock.site/mock/2689726b93437edee54ac2c3fa01a909/api',
      /* 接口地址 */
      url: '/user',
      /* 请求方式 */
      method: 'get',
      /* 修改headers */
      // headers: { 'Content-Type': 'multipart/form-data' },
      /* 指定响应中包含的数据类型 */
      // responseType: 'arraybuffer',
      params
    })
  }
}
