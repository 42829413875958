
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'

import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'ImageUpload',
  props: {
    // 上传的图片地址, 源
    url: {
      type: String,
      default: ''
    },
    // 上传文件大小限制，单位为MB
    size: {
      type: Number,
      default: 2
    },
    // 展示宽度
    width: {
      type: [Number, String],
      default: 150
    },
    // 展示高度
    height: {
      type: [Number, String],
      default: 150
    },
    // 占位图 (为上传时显示)
    placeholder: {
      type: String,
      default: ''
    },
    // 是否隐藏提示栏
    notip: {
      type: Boolean,
      default: false
    },
    // 自定义提示语
    notipTitle: {
      type: String,
      default: ''
    },
    // 支持的文件类型
    ext: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png', 'gif', 'bmp']
    }
  },
  setup (props, { emit }) {
    const store = useStore()

    const imgBoxRef = ref()

    const data = reactive({
      // 必选参数，上传的地址（根据接口）
      action: store.state.app.domainUrl + '/api/admin/file/upload',
      // 设置上传的请求头部
      headers: {
        token: store.state.user.token
      },
      // 进度条
      progress: {
        preview: '',
        percent: 0
      },

      // 图片预览
      onShowImg () {
        imgBoxRef.value.onPreview()
      },

      // 移除
      async remove () {
        // 模态框
        const confirmResult = await ElMessageBox.confirm('是否删除此图片？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => err)
        // 取消
        if (confirmResult !== 'confirm') return

        // 父级数据更新
        emit('update:url', '')
        ElMessage.success('删除成功！')
      },

      // 上传前
      beforeUpload (file: any) {
        // console.log('file', file)

        const isSizeOk = file.size / 1024 / 1024 < props.size
        const type = file.type.split('/')
        const isTypeOk = props.ext.indexOf(type[1]) >= 0

        if (!isTypeOk) {
          ElMessage.warning(`上传图片只支持 ${props.ext.join(' / ')} 格式！`)
        }
        if (!isSizeOk) {
          ElMessage.warning(`上传图片大小不能超过 ${props.size}MB！`)
        }
        if (isTypeOk && isSizeOk) {
          data.progress.preview = URL.createObjectURL(file)
        }
        return isTypeOk && isSizeOk
      },

      // 上传进度条
      onProgress (file: any) {
        data.progress.percent = ~~file.percent
        if (data.progress.percent === 100) {
          setTimeout(() => {
            data.progress.preview = ''
            data.progress.percent = 0
          }, 1000)
        }
      },

      // 上传成功（根据接口返回的信息改）
      onSuccess (res: any) {
        // console.log('上传成功', res)

        // 父级数据更新
        emit('update:url', res.res.url)
        // 回调函数
        emit('getSuccess', res)

        ElMessage.success('上传成功')
      },

      // 上传失败
      onError (error: any) {
        console.error('上传失败', error)
        ElMessage.error('上传失败')
      }
    })

    return {
      ...toRefs(data),
      imgBoxRef
    }
  }
})
