/*
 * @Description: 迭代管理-iteration
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  project_id: number
  name: string
  user_type1_name: string
  date_interval: Date[]
  status: number
}
// 保存
interface saveQuery {
  id?: number
  name: string
  project_id: number
  target: string
  start_date: Date
  end_date: Date
  user_id_type1: number
  user_ids_type2: number[]
}
// 详情、删除
interface queryId {
  id: number
}
// 更改项目状态
interface changeStatusQuery {
  id: number
  status: number
}

/* 接口定义 */
export class iterationApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/iteration/index',
      data
    })
  }

  // 保存
  static save (data: saveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/iteration/save',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/iteration/info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/iteration/del',
      data
    })
  }

  // 更改项目状态
  static changeStatus (data: changeStatusQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/iteration/change_status',
      data
    })
  }
}
