import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-031b0dfd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onGoHome && _ctx.onGoHome(...args))),
    class: _normalizeClass(["title", {
      'is-link': _ctx.$store.state.settings.enableDashboard,
    }])
  }, [
    (_ctx.showLogo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.logo,
          class: "logo_img"
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.showTitle)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true)
  ], 2))
}