/*
 * @Description: 成员管理-members
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  username: string
  mobile: string
  positionName: string
}
// 保存
interface saveQuery {
  user_id?: number
  username: string
  roleId: number
  mobile: string | number
  avatar: string
  positionId: number
}
// 详情、删除
interface queryId {
  user_id: number
}
// 修改密码
interface changePdQuery {
  user_id: number
  pd: string
}
// 启用/禁用
interface changeStatusQuery {
  user_id: number
  status: number
}

/* 接口定义 */
export class membersApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/user/index',
      data
    })
  }

  // 保存
  static save (data: saveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/user/save',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/user/info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/user/del',
      data
    })
  }

  // 修改密码
  static changePd (data: changePdQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/user/change_pd',
      data
    })
  }

  // 启用/禁用
  static changeStatus (data: changeStatusQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/user/change_status',
      data
    })
  }
}
