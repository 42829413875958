/* 项目管理 */
import { RouteRecordRaw } from 'vue-router'

import Layout from '@/layout/index.vue'
import EmptyLayout from '@/layout/empty.vue'

/* 有子集 */
const route: RouteRecordRaw | any = {
  path: '/project',
  name: 'project',
  redirect: '/project/list',
  subset: true,
  component: Layout,
  meta: {
    id: 26,
    title: '项目管理',
    icon: 'project'
  },
  children: [
    {
      path: 'list',
      name: 'projectList',
      redirect: '/project/list',
      component: EmptyLayout,
      meta: {
        id: 27,
        parent_id: 26,
        title: '项目列表'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/project/list/index.vue'),
          meta: {
            id: 62,
            parent_id: 27,
            title: '项目列表',
            sidebar: false,
            breadcrumb: false
          }
        },
        {
          path: 'details',
          name: 'projectDetails',
          component: () => import('@/views/project/list/details.vue'),
          meta: {
            id: 30,
            parent_id: 27,
            title: '项目详情',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'operationManual/add',
          name: 'operationManualAdd',
          component: () => import('@/views/project/list/components/helpCenter/operationManual/itemAdd.vue'),
          meta: {
            id: 41,
            parent_id: 27,
            title: '操作手册-新增',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'operationManual/edit',
          name: 'operationManualEdit',
          component: () => import('@/views/project/list/components/helpCenter/operationManual/itemEdit.vue'),
          meta: {
            id: 42,
            parent_id: 27,
            title: '操作手册-编辑',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'operationManual/show',
          name: 'operationManualShow',
          component: () => import('@/views/project/list/components/helpCenter/operationManual/itemShow.vue'),
          meta: {
            id: 43,
            parent_id: 27,
            title: '操作手册-查看',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'hotIssue/add',
          name: 'helpCenterHotIssueAdd',
          component: () => import('@/views/project/list/components/helpCenter/hotIssue/add.vue'),
          meta: {
            id: 48,
            parent_id: 27,
            title: '热门问题-新增',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'hotIssue/edit',
          name: 'helpCenterHotIssueEdit',
          component: () => import('@/views/project/list/components/helpCenter/hotIssue/edit.vue'),
          meta: {
            id: 49,
            parent_id: 27,
            title: '热门问题-编辑',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'hotIssue/show',
          name: 'helpCenterHotIssueShow',
          component: () => import('@/views/project/list/components/helpCenter/hotIssue/show.vue'),
          meta: {
            id: 50,
            parent_id: 27,
            title: '热门问题-查看',
            sidebar: false,
            activeMenu: '/project/list'
          }
        },
        {
          path: 'feedback/show',
          name: 'helpCenterFeedbackShow',
          component: () => import('@/views/project/list/components/helpCenter/feedback/show.vue'),
          meta: {
            id: 56,
            parent_id: 27,
            title: '意见反馈-查看',
            sidebar: false,
            activeMenu: '/project/list'
          }
        }
      ]
    },
    {
      path: 'typeManagement',
      name: 'projectTypeManagement',
      redirect: '/project/typeManagement',
      component: EmptyLayout,
      meta: {
        id: 21,
        parent_id: 26,
        title: '项目类型管理'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/project/typeManagement/index.vue'),
          meta: {
            id: 67,
            parent_id: 21,
            title: '项目类型管理',
            sidebar: false,
            breadcrumb: false
          }
        }
      ]
    }
  ]
}
export default route
