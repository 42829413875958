import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      _createVNode(_Transition, {
        name: "main",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_KeepAlive, {
            include: _ctx.$store.state.keepAlive.list
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1032, ["include"]))
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }))
}