<!-- 侧边栏图标， 支持 element图标 和 svg -->
<script>
export default {
  name: 'MenuItem',
  // 设置为函数化组件
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    badge: {
      type: [String, Boolean, Number],
      default: ''
    },
    // 是否有子集
    ifSubset: {
      type: Boolean,
      default: false
    }
  },
  render (context) {
    // console.log('context', context)
    const { icon, title, badge, ifSubset } = context
    const vnodes = []

    if (icon) {
      vnodes.push(
        <div class="icon-i">
          <svg-icon name={icon} />
        </div>
      )
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>)
    }

    if (badge) {
      if (typeof badge !== 'boolean') {
        vnodes.push(
          <el-tag class="RedDot" style={ifSubset ? 'right: 40px;' : 'right: 20px;'} size="mini" effect="dark" type="danger">
            {badge}
          </el-tag>
        )
      } else {
        vnodes.push(
          <div class="littleRedDot">
            <span />
          </div>
        )
      }
    }

    return vnodes
  }
}
</script>

<style scoped lang="scss">
.sidebarItem-icon {
  margin-right: 10px;
  width: 1em;
  height: 1em;
  display: inline;

  .icon-i {
    width: 1em !important;
    height: 1em;
    fill: currentColor;
    overflow: hidden;
    margin: 0 !important;
    font-size: 20px;
    color: #414141;
    transition: all 0.3s;
    vertical-align: -0.2em;
  }
}
.is-collapse {
    .icon-i {
    width: 1000px;
    }
}

.RedDot {
  overflow: hidden;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  padding: 0 6px;
  height: 20px;
  line-height: 18px;
  border-radius: 4px;
  border: 1px solid #fff !important;
}

.littleRedDot {
  position: absolute;
  top: 50%;
  right: 26px;
  margin-top: -3px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
  background: red;

  span {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: vabDot 1.2s ease-in-out infinite;
    background: red;

    @keyframes vabDot {
      0% {
        opacity: 0.6;
        transform: scale(0.8);
      }
      to {
        opacity: 0;
        transform: scale(2.4);
      }
    }
  }
}
</style>
