import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import NProgress from 'nprogress'
import Router from '@/router'
import userStore from '@/store/modules/user'
import menuStore from '@/store/modules/menu'
import TagsViewStore from '@/store/modules/TagsView'

/* 配置请求域名 */
export const baseURL = 'https://project-api.ozkoalas.cn'

/* 创建axios的一个实例 */
const instance = axios.create({
  baseURL,
  // baseURL: '/http', // 配置跨域
  timeout: 15000, // 请求超时毫秒数
  method: 'post'
})

/* 请求拦截器 */
instance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    // 进度条开启
    NProgress.start()

    // 登陆后请求携带 token
    const token = userStore.state.token
    if (token) {
      config.headers.token = token
    }

    return config
  },
  (error) => {
    // 抛出服务器响应错误
    return Promise.reject(error)
  }
)

/* 响应拦截器 */
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 进度条关闭
    NProgress.done()

    const res = response.data
    // console.log('请求结果', res)

    // 判断服务器返回的数据
    if (res.code && res.code !== 1) {
      ElMessage({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })

      // 未登录/没有权限
      if (res.code === -90001 || res.code === -90002) {
        const title = res.code === -90001 ? '登录已过期' : '抱歉，您没有权限访问'
        ElMessageBox.alert(`${title}，请重新登录！`, '提示', {
          showClose: false,
          showCancelButton: res.code !== -90001,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          // 登出
          userStore.mutations.removeUserData(userStore.state)
          menuStore.mutations.invalidRoutes(menuStore.state)
          TagsViewStore.mutations.Empty(TagsViewStore.state)

          Router.push({
            path: '/login'
          })
        })
      }

      return Promise.reject(res.msg)
    } else {
      return response.data
    }
  },
  (error) => {
    // 进度条关闭
    NProgress.done()

    // 抛出服务器响应错误
    ElMessage({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

/* 后端数据结构定义 */
export interface HttpResponse {
  [key: string]: any
}

export default instance
