/*
 * @Description: 热门问题-hotIssue
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  project_id: number
  name: string
  status: number
}
// 保存
interface saveQuery {
  id?: number
  name: string
  project_id: number
  answer: string
  detail: string
}
// 详情、删除
interface queryId {
  id: number
}
// 更改项目状态
interface changeStatusQuery {
  id: number
  status: number
}
// 排序
interface changeSortQuery {
  project_id: number
  ids: number[]
  sorts: number[]
}

/* 接口定义 */
export class hotIssueApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/problem/index',
      data
    })
  }

  // 保存
  static save (data: saveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/problem/save',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/problem/info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/problem/del',
      data
    })
  }

  // 更改项目状态
  static changeStatus (data: changeStatusQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/problem/change_status',
      data
    })
  }

  // 排序
  static changeSort (data: changeSortQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/problem/change_sort',
      data
    })
  }
}
