
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { compile } from 'path-to-regexp'
import UserMenu from '../UserMenu/index.vue'

export default defineComponent({
  name: 'Breadcrumb',
  components: {
    UserMenu
  },
  setup () {
    const route = useRoute()
    const store = useStore()

    const data = reactive({
      breadcrumbList: computed(() => {
        const breadcrumbList = []
        if (route.path === '/dashboard') {
          breadcrumbList.push({
            path: '/dashboard',
            title: '控制台'
          })
        }

        route.matched.map((item: any) => {
          if (item.meta && item.meta.title && item.meta.breadcrumb !== false && item.path !== '/dashboard') {
            breadcrumbList.push({
              path: item.path,
              title: item.meta.title
            })
          }
        })
        return breadcrumbList
      }),

      // 路由跳转
      pathCompile (path: string) {
        const toPath = compile(path)
        return toPath(route.params)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
