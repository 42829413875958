/*
 * @Description: 意见反馈-feedback
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  project_id: number
  title: string
}
// 保存
interface remarkQuery {
  id?: number
  remark: string
}
// 详情、删除
interface queryId {
  id: number
}

/* 接口定义 */
export class feedbackApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/opinion/index',
      data
    })
  }

  // 备注
  static remark (data: remarkQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/opinion/remark',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/opinion/info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/opinion/del',
      data
    })
  }
}
