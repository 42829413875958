/*
 * @Description: 负责记录页面缓存的组件 name 名
 */

interface stateType<T> {
  list: Array<T>
}

const state = {
  list: ['projectDetails']
}

const getters = {}

const actions = {}

const mutations = {
  add (state: stateType<string>, name: string): any {
    !state.list.includes(name) && state.list.push(name)
  },
  remove (state: stateType<string>, name: string): void {
    state.list = state.list.filter((v) => {
      return v !== name
    })
  },
  clean (state: stateType<string>): void {
    state.list = []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
