/*
 * @Description: 页面内容
 */
import { baseURL } from '@/api/request'

const state = {
  // 域名
  domainUrl: baseURL
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
