/*
 * @Description: 标签导航栏
 */

interface stateType<T> {
  tagsList: Array<T>
  delTags: any
}
const state = {
  tagsList: [], // 存储标签
  delTags: null // 存储被删除的标签
}

const getters = {}

const actions = {
  // 删除
  DEL_Tags_A ({ state, commit }: any, index: number): void {
    commit('DEL_Tags', index)
    return state.delTags
  }
}

const mutations = {
  // 存储
  SET_Tags: (state: stateType<any>, tags: any): void => {
    state.tagsList.push(tags)
  },
  // 相同但是参数不一样覆盖
  PUT_Tags: (state: stateType<any>, data: any): void => {
    // console.log(data.index, data.route)
    state.tagsList[data.index].query = data.route.query
    state.tagsList[data.index].fullPath = data.route.fullPath
  },
  // 直接赋值 tagsList
  ASSIGNMENT_Tags: (state: stateType<any>, tags: any): void => {
    state.tagsList = tags
  },

  // 删除
  DEL_Tags: (state: stateType<any>, index: number): void => {
    state.delTags = state.tagsList.splice(index, 1)[0]
  },
  // 根据路由name删除
  DEL_Name_Tags: (state: stateType<any>, name: string): void => {
    const index = state.tagsList.findIndex((i) => {
      return i.name === name
    })
    state.tagsList.splice(index, 1)
  },

  // 清空
  Empty: (state: stateType<any>): void => {
    state.tagsList = []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
