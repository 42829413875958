
import { defineComponent, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

// 引入 wangEditor
import WangEditor from 'wangeditor'

import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Wangeditor',
  props: {
    // 编辑器id
    wangeditorId: {
      required: true,
      type: String,
      default: null
    },
    // 编辑器高度
    height: {
      type: Number,
      default: 500
    },
    // 提示文字
    placeholder: {
      type: String,
      default: '请输入您的精彩内容'
    }
  },
  setup (props, { emit }) {
    const store = useStore()

    onMounted(() => {
      data.init()
    })

    const data: any = reactive({
      // 编辑器
      editor: null,
      // 内容
      editorData: '',

      // 获取文本
      Wangeditor_getValue () {
        // 获取编辑器内容
        // console.log('获取text文本：------', data.editor.txt.text())
        // console.log('获取JSON：------', data.editor.txt.getJSON())
        return data.editor.txt.html()
      },
      // 编辑回填
      Wangeditor_setValue (value: string) {
        /* 重新设置编辑器内容（编辑时回填） */
        data.editor.txt.html(value)
      },
      // 追加新内容
      Wangeditor_onAdd (value: string) {
        data.editor.txt.append(value)
      },
      // 启用编辑器
      Wangeditor_onEnable () {
        data.editor.enable()
      },
      // 禁用编辑器
      Wangeditor_onDisable () {
        data.editor.disable()
      },
      // 清空内容
      Wangeditor_onClear () {
        data.editor.txt.clear()
      },

      // 初始化
      async init () {
        /* 注册编辑器 */
        const editor = new WangEditor('#' + props.wangeditorId)

        /*  配置 onchange 回调函数，将数据同步到 vue 中 */
        editor.config.onchange = (newHtml: any) => {
          data.editorData = newHtml
        }

        // 编辑器 z-index 默认为 10000
        editor.config.zIndex = 1

        /* 设置编辑区域高度为 500px */
        editor.config.height = props.height

        /* 配置placeholder，不想使用 placeholder ，赋值为空字符串即可  */
        editor.config.placeholder = props.placeholder

        /* 取消自动 focus */
        // editor.config.focus = false

        /* 配置菜单栏，删减菜单，调整顺序 */
        // editor.config.menus = ['bold', 'head', 'link', 'italic', 'underline']

        /* 配置菜单栏，设置不需要的菜单 */
        // editor.config.excludeMenus = ['emoticon', 'video']

        /* 配置全屏功能按钮是否展示 */
        // editor.config.showFullScreen = true

        /* 关闭粘贴样式的过滤 false:来关闭样式过滤 */
        // editor.config.pasteFilterStyle = false

        /* 忽略粘贴内容中的图片 */
        // editor.config.pasteIgnoreImg = true

        /* base64 保存图片 (和 上传图片到服务器 两者不能同时） */
        // editor.config.uploadImgShowBase64 = true

        /* 图片上传配置 */
        await data.imageUploadInit(editor)

        /* 隐藏插入网络图片的功能，即只保留上传本地图片 */
        // editor.config.showLinkImg = false

        /* 网络图片设置alt和跳转链接 */
        // 配置alt选项
        // editor.config.showLinkImgAlt = false
        // 配置超链接
        // editor.config.showLinkImgHref = false

        /* 视频上传配置 */
        await data.videoUploadInit(editor)

        /* 隐藏插入网络视频的功能 */
        // editor.config.showLinkVideo = false

        /* 创建编辑器 */
        editor.create()
        data.editor = editor

        emit('init')
      },

      /*
        图片上传配置：
          https://www.wangeditor.com/doc/pages/07-%E4%B8%8A%E4%BC%A0%E5%9B%BE%E7%89%87/01-%E9%85%8D%E7%BD%AE%E6%9C%8D%E5%8A%A1%E7%AB%AF%E6%8E%A5%E5%8F%A3.html
      */
      imageUploadInit (editor: any) {
        return new Promise<void>((resolve) => {
          /* 配置 server 接口地址 */
          editor.config.uploadImgServer = store.state.app.domainUrl + '/api/admin/file/upload'
          /* 限制大小 （默认5M） */
          // editor.config.uploadImgMaxSize = 2 * 1024 * 1024 // 2M
          /* 限制大小 （默认['jpg', 'jpeg', 'png', 'gif', 'bmp']）， 如果不希望限制类型，可将其设为空数组 */
          // editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
          /* 限制一次最多能传几张图片 */
          editor.config.uploadImgMaxLength = 1
          // formdata中的name属性
          editor.config.uploadFileName = 'file'
          // 设置请求头
          editor.config.uploadImgHeaders = {
            token: store.state.user.token
          }
          // 回调函数
          editor.config.uploadImgHooks = {
            // 图片上传并返回结果，图片插入成功之后触发
            success: function (xhr: any, editor: any, result: any) {
              console.log('图片插入成功', result)
            },
            // 图片上传并返回了结果，但图片插入时出错了
            fail: function (xhr: any, editor: any, resData: any) {
              console.error('插入时出错了', resData)
            },
            // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert, 上面的函数不会执行
            customInsert: function (insertImgFn: any, result: any) {
              // result 即服务端返回的接口
              console.log('customInsert', result)

              // 服务器错误
              if (result.code !== 1) {
                return ElMessage.error(result.msg)
              }

              // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
              // store.state.app.domainUrl + '/' + result.res
              insertImgFn(store.state.app.domainUrl + result.res.url)
              ElMessage.success('图片插入成功')
            },
            // 上传图片出错，一般为 http 请求的错误
            error: function (xhr: any, editor: any, resData: any) {
              console.error('上传图片出错', xhr, resData)
            },
            // 上传图片超时
            timeout: function (xhr: any) {
              console.error('上传图片超时', xhr)
            }
          }

          resolve()
        })
      },

      /*
        视频上传配置（和图片上传类似）
          https://www.wangeditor.com/doc/pages/07-%E4%B8%8A%E4%BC%A0%E8%A7%86%E9%A2%91/01-%E9%85%8D%E7%BD%AE%E6%9C%8D%E5%8A%A1%E7%AB%AF%E6%8E%A5%E5%8F%A3.html
      */
      videoUploadInit (editor: any) {
        return new Promise<void>((resolve) => {
          // 配置 server 接口地址
          editor.config.uploadVideoServer = store.state.app.domainUrl + '/api/admin/file/upload'
          // 限制大小,类型 (默认限制视频大小是 1024m)
          // editor.config.uploadVideoMaxSize = 1 * 1024 * 1024 * 1024 // 1024m
          // editor.config.uploadVideoAccept = ['mp4']
          // formdata中的name属性
          editor.config.uploadVideoName = 'file'
          // 设置请求头
          editor.config.uploadVideoHeaders = {
            token: store.state.user.token
          }
          // 回调函数
          editor.config.uploadVideoHooks = {
            // 视频上传并返回了结果，视频插入已成功
            success: function (xhr: any) {
              console.log('图片插入成功', xhr)
            },
            // 视频上传并返回了结果，但视频插入时出错了
            fail: function (xhr: any, editor: any, resData: any) {
              console.error('插入时出错了', resData)
            },
            // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert, 上面的函数不会执行
            customInsert: function (insertVideoFn: any, result: any) {
              // result 即服务端返回的接口
              console.log('customInsert', result)

              // 服务器错误
              if (result.code !== 1) {
                return ElMessage.error(result.msg)
              }

              // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
              // store.state.app.domainUrl + '/' + result.res
              insertVideoFn(store.state.app.domainUrl + result.res.url)
              ElMessage.success('视频插入成功')
            },
            // 上传视频出错，一般为 http 请求的错误
            error: function (xhr: any, editor: any, resData: any) {
              console.error('上传图片出错', xhr, resData)
            },
            // 上传视频超时
            timeout: function (xhr: any) {
              console.error('上传视频超时', xhr)
            }
          }

          resolve()
        })
      }
    })

    onBeforeUnmount(() => {
      // console.log('onBeforeUnmount-进行销毁')
      // 调用销毁 API 对当前编辑器实例进行销毁
      data.editor && data.editor.destroy()
    })

    return {
      ...toRefs(data)
    }
  }
})
