/*
 * @Description: 负责生成导航菜单的路由数据
 */
import { deepClone } from '@/utils'

interface stateType<T> {
  isGenerate: boolean
  headerActived: number
  routes: Array<T>
  permissions: Array<T>
}

const state = {
  isGenerate: false,
  // 一级导航栏
  headerActived: 0,
  // 过滤后的权限路由
  routes: [],
  // 接口获取路由权限
  permissions: []
}

const getters = {
  sidebarRoutes: (state: stateType<any>): any => {
    return state.routes[state.headerActived].children
  }
}

const actions = {
  // 根据权限动态生成路由
  generateRoutes ({ rootState, dispatch, commit }: any, data: any): any {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let accessedRoutes
      // 判断权限功能是否开启
      if (rootState.settings.openPermission) {
        const permissions = await dispatch('user/getPermissions', null, { root: true })
        commit('setPermissions', permissions)

        accessedRoutes = await filterAsyncRoutes(data.asyncRoutes, permissions)
      } else {
        accessedRoutes = data.asyncRoutes
      }
      console.log('accessedRoutes', accessedRoutes)

      commit('setRoutes', accessedRoutes)
      commit('setHeaderActived', data.currentPath)

      const routes: any = []
      accessedRoutes.map((item: any) => {
        routes.push(...item.children)
      })
      console.log('routes', routes)

      resolve(routes)
    })
  }
}

const mutations = {
  invalidRoutes (state: stateType<any>): void {
    state.isGenerate = false
    state.headerActived = 0
  },

  setPermissions (state: stateType<any>, permissions: any): void {
    state.permissions = permissions
  },

  // 存储路由
  setRoutes (state: stateType<any>, routes: any): void {
    state.isGenerate = true
    const newRoutes = deepClone(routes)
    state.routes = newRoutes.filter((item: any) => {
      return item.children.length !== 0
    })
  },

  // 根据路由判断属于哪个头部导航
  setHeaderActived (state: stateType<any>, path: string): void {
    state.routes.map((item, index) => {
      if (
        item.children.some((r: any) => {
          return path.indexOf(r.path + '/') === 0 || path === r.path
        })
      ) {
        state.headerActived = index
      }
    })
  },

  // 切换头部导航
  switchHeaderActived (state: stateType<any>, index: any): void {
    state.headerActived = index
  },

  /**
   * @description 修改Meta
   * @param {*} state
   * @param options
   */
  changeMenuMeta (state: stateType<any>, options: any): void {
    function handleRoutes (routes: any) {
      return routes.map((route: any) => {
        if (route.name === options.name) {
          route.meta = Object.assign({}, route.meta, options.meta)
        }
        if (route.children && route.children.length) {
          route.children = handleRoutes(route.children)
        }
        return route
      })
    }

    state.routes = handleRoutes(state.routes)
  }
}

/**
 * @description: 权限匹配对应路由
 * @param {*} routes 本地路由
 * @param {*} permissions 接口获取到的权限路由
 * @return {*}
 */
function filterAsyncRoutes (routes: any, permissions: any) {
  const res: any = []

  /* 只提取本地路由第一级 */
  // console.log('routes[0].children', routes[0].children)

  routes.forEach((route: any) => {
    const tmp = { ...route }
    // console.log('tmp', tmp)
    for (const item of permissions) {
      // console.log('item', item)

      // 如果一层不匹配就不需要再继续进行了
      if (item.id === tmp.meta.id) {
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(tmp.children, item.children)
          tmp.children.length && res.push(tmp)
        } else {
          res.push(tmp)
        }
      }
    }
  })

  return res
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
