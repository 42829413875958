import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, Transition as _Transition, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61a312f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "header-container" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = {
  key: 0,
  class: "nav"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "wrapper" }
const _hoisted_9 = {
  key: 0,
  class: "main-sidebar-container"
}
const _hoisted_10 = { class: "nav" }
const _hoisted_11 = ["title", "onClick"]
const _hoisted_12 = { class: "main-container" }
const _hoisted_13 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_UserMenu = _resolveComponent("UserMenu")!
  const _component_SidebarItem = _resolveComponent("SidebarItem")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_TagsView = _resolveComponent("TagsView")!
  const _component_Topbar = _resolveComponent("Topbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_backtop = _resolveComponent("el-backtop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "app-main",
      style: _normalizeStyle({ '--real-sidebar-width': _ctx.realSidebarWidth })
    }, [
      (_ctx.$store.state.settings.showHeader)
        ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Logo),
                (_ctx.$store.state.menu.routes.length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.menu.routes, (item, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (item.children && item.children.length !== 0)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass({
                    item: true,
                    active: index == _ctx.$store.state.menu.headerActived,
                  }),
                                onClick: ($event: any) => (_ctx.$store.commit('menu/switchHeaderActived', index))
                              }, [
                                (item.meta.icon)
                                  ? (_openBlock(), _createBlock(_component_svg_icon, {
                                      key: 0,
                                      name: item.meta.icon
                                    }, null, 8, ["name"]))
                                  : _createCommentVNode("", true),
                                (item.meta.title)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.meta.title), 1))
                                  : _createCommentVNode("", true)
                              ], 10, _hoisted_6))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 256))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_UserMenu)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass({
            'sidebar-container': true,
            show: !_ctx.$store.state.settings.sidebarCollapse,
          })
        }, [
          (!_ctx.$store.state.settings.showHeader && _ctx.$store.state.menu.routes.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_Logo, {
                  showTitle: false,
                  class: "sidebar-logo"
                }),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.menu.routes, (item, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (item.children && item.children.length !== 0)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: _normalizeClass({
                    item: true,
                    active: index == _ctx.$store.state.menu.headerActived,
                  }),
                            title: item.meta.title,
                            onClick: ($event: any) => (_ctx.$store.commit('menu/switchHeaderActived', index))
                          }, [
                            (item.meta.icon)
                              ? (_openBlock(), _createBlock(_component_svg_icon, {
                                  key: 0,
                                  name: item.meta.icon
                                }, null, 8, ["name"]))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", null, _toDisplayString(item.meta.title), 1)
                          ], 10, _hoisted_11))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass({
              'sub-sidebar-container': true,
              'is-collapse': _ctx.$store.state.settings.sidebarCollapse,
            }),
            onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSidebarScroll && _ctx.onSidebarScroll(...args)))
          }, [
            _createVNode(_component_Logo, {
              showLogo: _ctx.$store.state.menu.routes.length <= 1,
              class: _normalizeClass({
                'sidebar-logo': true,
                'sidebar-logo-bg': _ctx.$store.state.menu.routes.length <= 1,
                shadow: _ctx.sidebarScrollTop,
              })
            }, null, 8, ["showLogo", "class"]),
            _createVNode(_component_el_menu, {
              "background-color": _ctx.variables.g_sub_sidebar_bg,
              "text-color": _ctx.variables.g_sub_sidebar_menu_color,
              "active-text-color": _ctx.variables.g_sub_sidebar_menu_active_color,
              "default-active": _ctx.$route.meta.activeMenu || _ctx.$route.path,
              collapse: _ctx.$store.state.settings.sidebarCollapse,
              "collapse-transition": false,
              class: _normalizeClass({
                'is-collapse-without-logo': _ctx.$store.state.menu.routes.length > 1 && _ctx.$store.state.settings.sidebarCollapse,
              })
            }, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, { name: "sidebar" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters['menu/sidebarRoutes'], ($route) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        ($route.meta.sidebar !== false)
                          ? (_openBlock(), _createBlock(_component_SidebarItem, {
                              key: $route.path,
                              item: $route,
                              "base-path": $route.path
                            }, null, 8, ["item", "base-path"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["background-color", "text-color", "active-text-color", "default-active", "collapse", "class"])
          ], 34)
        ], 2),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", {
            class: _normalizeClass(["main-header", { shadow: _ctx.scrollTop, top0: !_ctx.$store.state.settings.showHeader }])
          }, [
            _createVNode(_component_TagsView),
            _createVNode(_component_Topbar)
          ], 2),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component }) => [
                _createVNode(_Transition, {
                  name: "main",
                  mode: "out-in"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isRouterAlive)
                      ? (_openBlock(), _createBlock(_KeepAlive, {
                          key: 0,
                          include: _ctx.$store.state.keepAlive.list
                        }, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ], 1032, ["include"]))
                      : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 1 }))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createVNode(_component_el_backtop, {
        right: 20,
        bottom: 20,
        title: "回到顶部"
      })
    ], 4)
  ]))
}