
import { defineComponent, onBeforeMount, reactive, toRefs, watch, getCurrentInstance } from 'vue'
import { ElSelect } from 'element-plus'

export default defineComponent({
  name: 'VEselect',
  props: {
    ...ElSelect.props, // 这里继承原 UI 组件的 props
    // 值
    selectValue: {
      type: [String, Number, Array]
    },
    // 数据项值别名
    objKey: {
      type: String,
      default: 'value'
    },
    // 数据项属性别名
    objLabel: {
      type: String,
      default: 'label'
    },
    // 请求地址 (文件名，方法名)
    // :apiUrl="['sports', 'getList']"
    apiUrl: {
      required: true,
      type: Array
    },
    // 查询名称字段
    queryName: {
      type: String
    },
    // 查询字段：状态
    queryStatus: {
      type: Number
    },
    // 更多查询字段
    queryData: {
      type: Object
    },
    // 已选中的数据
    selectedObj: {
      type: [Object, Array],
      default: null
    }
  },
  setup (props, { emit }) {
    // 获取全局变量
    const { proxy } = getCurrentInstance() as any

    onBeforeMount(async () => {
      // 动态导入接口地址
      const name = props.apiUrl[0]
      const importApi = () => require('@/api/modules/' + name)
      // console.log('importApi', await importApi())
      data.apiName = await importApi()[name + 'Api']

      data.init()

      setTimeout(() => {
        data.getList()
      }, 200)
    })

    const data: any = reactive({
      apiName: null,
      localVal: props.selectValue,
      // 加载动画
      load: false,
      // 查询
      query: {
        paginate: 10,
        page: 1
      },
      whatLoading: true,
      list: [],

      // 数据请求初始化
      init (queryFun: number) {
        const query = {
          paginate: 10,
          page: 1
        }
        data.query = query
        data.whatLoading = true
        data.list = []

        // 已有选中值新增入选择
        if (props.selectedObj && !queryFun) {
          // console.log('props.selectedObj', props.selectedObj)
          if (props.multiple) {
            data.list = props.selectedObj
          } else {
            data.list = [props.selectedObj]
          }
        }
      },

      async getList (queryFun: number, query: any) {
        // 搜索
        if (queryFun) {
          data.init(1)
          data.query[props.queryName] = query
        }

        // 是否需要带状态
        if (props.queryStatus) {
          data.query.status = props.queryStatus
        }

        // 更多查询字段
        if (props.queryData) {
          data.query = Object.assign(data.query, props.queryData)
        }
        const { res } = await data.apiName[props.apiUrl[1]](data.query)
        // console.log('获取选择列表', res.data)

        data.load = false
        if (res.data.length === 0) {
          data.whatLoading = false
          return
        }

        // 过滤已有数据
        let newArr = null
        if (props.selectedObj && !queryFun) {
          if (props.multiple) {
            newArr = res.data.filter((obj: any) => !props.selectedObj.some((obj1: any) => obj1[props.objKey] === obj[props.objKey]))
          } else {
            newArr = res.data.filter((obj: any) => obj[props.objKey] !== props.selectedObj[props.objKey])
          }
        } else {
          newArr = res.data
        }
        data.list = [...data.list, ...newArr]
      },

      // 分页加载更多
      handleLoadMore () {
        // console.log('分页加载更多')
        if (!data.whatLoading || data.list < 10) return
        data.query.page++
        data.getList()
      },

      // 远程搜索
      remoteMethodFun (query: any) {
        proxy.$globalFun.debounce(() => {
          data.load = true
          data.getList(1, query)
        })
      },

      // 下拉框出现/隐藏时触发 出现则为 true，隐藏则为 false
      visibleChangeFun (val: any) {
        // 搜索框有值的话隐藏选择清空数据
        if (!data.query[props.queryName]) return
        data.init()
        data.getList()

        emit('visible-change', val)
      },

      // 多选模式下移除tag时触发
      removeTagFun (val: any) {
        emit('remove-tag', val)
      },

      // 可清空的单选模式下用户点击清空按钮时触发
      clearFun () {
        emit('clear')
      },

      // 当 input 失去焦点时触发
      blurFun (e: any) {
        emit('blur', e)
      },

      // 当 input 获得焦点时触发
      focusFun (e: any) {
        emit('focus', e)
      },

      // 选中
      changeFun (val: any) {
        // console.log('选中-val', val)
        // 更新selectValue
        emit('update:selectValue', val)

        // 事件传值
        emit('change', val)
      }
    })

    watch(
      () => props.selectValue,
      (val) => {
        data.localVal = val
      }
    )

    return {
      ...toRefs(data)
    }
  },
  directives: {
    'el-select-loadmmore': {
      mounted (el, binding) {
        // console.log('el', el)

        /* 根据ariadescribedby拿id */
        const child = el.childNodes[1]
        // console.log('child', child)
        const id = child.getAttribute('aria-describedby')
        // console.log('id', id)
        const poper: any = document.getElementById(id)
        // console.log('poper', poper)
        const SELECTDOWN_DOM: any = poper.querySelector('.el-scrollbar .el-select-dropdown__wrap')
        // console.log('SELECTDOWN_DOM', SELECTDOWN_DOM)

        // 为对应的ul绑定滚动条滚动事件
        // 这里不能使用箭头函数！
        // eslint-disable-next-line func-names
        SELECTDOWN_DOM.addEventListener('scroll', function (this: any) {
          /**
           * scrollHeight 获取元素内容高度(只读)
           * scrollTop 获取或者设置元素的偏移值,
           *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
           * clientHeight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
           */
          const CONDITION: any = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (CONDITION) {
            binding.value()
          }
        })
      }
    }
  }
})
