/*
 * @Description: 权限
 *  获取当前路由中 meta的parent_id 字段，
 *  匹配接口中的权限列表，
 *  在根据 权限名称 匹配是否有权限
 *
 *  用法：
 *  v-if="$globalFun.ifAuth($route, '权限名称')"
 *  const { proxy } = getCurrentInstance() as any
 *  proxy.$globalFun.ifAuth($route, '权限名称')
 */

import settings from '@/store/modules/settings'
import menu from '@/store/modules/menu'

/**
 * @description: 判断是否有权限（用于按钮、详情内容）
 * @param {*} route 当前路由
 * @param {*} title 权限名称
 * @return {*}
 */
export function ifAuth (route: any, title: string): any {
  if (settings.state.openPermission) {
    /* 开启权限 */
    const permissions = menu.state.permissions
    // console.log('接口权限列表', permissions)
    // console.log('route', route.meta.parent_id)

    const permissionsItem = findID(permissions, route.meta.parent_id)
    // console.log('permissionsItem', permissionsItem)

    return permissionsItem.children.some((item: any) => {
      return item.name === title
    })
  } else {
    return true
  }
}

// 递归出是否存在，存在跳出递归
const findID: any = (permissions: any, id: any) => {
  for (let i = 0; i < permissions.length; i++) {
    const item = permissions[i]

    if (item.id === id) {
      // console.log('item', item)
      return item
    } else {
      if (item.children && item.children.length) {
        if (findID(item.children, id)) {
          return findID(item.children, id)
        }
      }
    }
  }
}
