
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    size: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: null
    }
  },
  setup () {
    const data = reactive({})

    return {
      ...toRefs(data)
    }
  }
})
