
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive, toRefs, nextTick, ref, inject } from 'vue'
import { useStore } from 'vuex'

const tagAndTagSpacing = 4 // tagAndTagSpacing

export default defineComponent({
  name: 'ScrollPane',
  emits: ['scroll'],
  setup (props, ctx) {
    const store = useStore()

    onBeforeMount(() => {
      nextTick(() => {
        data.scrollWrapper.addEventListener('scroll', data.emitScroll, true)
      })
    })

    onBeforeUnmount(() => {
      data.scrollWrapper.removeEventListener('scroll', data.emitScroll)
    })

    const scrollContainerRef: any = ref()

    const data: any = reactive({
      left: 0,
      // 获取父级注入内容
      tagBoxRef: inject('tagBoxRef'),

      scrollWrapper: computed(() => {
        // console.log('scrollContainerRef', scrollContainerRef.value)
        if (!scrollContainerRef.value) return
        return scrollContainerRef.value.wrap$
      }),

      handleScroll (e: any) {
        const eventDelta = e.wheelDelta || -e.deltaY * 40
        const $scrollWrapper = data.scrollWrapper
        $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
      },
      emitScroll () {
        ctx.emit('scroll')
      },
      moveToTarget (currentTag: any) {
        const $container = scrollContainerRef.value.$el
        const $containerWidth = $container.offsetWidth
        const $scrollWrapper = data.scrollWrapper
        const tagList = store.state.TagsView.tagsList
        // console.log('tagList', tagList)

        let firstTag = null
        let lastTag = null

        // 找到第一个标签和最后一个标签
        if (tagList.length > 0) {
          firstTag = tagList[0]
          lastTag = tagList[tagList.length - 1]
        }

        if (firstTag.fullPath === currentTag.fullPath) {
          $scrollWrapper.scrollLeft = 0
        } else if (lastTag.fullPath === currentTag.fullPath) {
          $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth
        } else {
          // console.log('data.tagBoxRef', data.tagBoxRef)
          const list = Array.from(data.tagBoxRef.$el.children)
          // console.log('list', list)
          // console.log('currentTag', currentTag)

          // find preTag and nextTag
          const currentIndex = list.findIndex((item: any) => item.innerText === currentTag.meta.title)
          // console.log('currentIndex', currentIndex)
          const prevTag: any = list[currentIndex - 1]
          const nextTag: any = list[currentIndex + 1]
          // console.log('prevTag', prevTag)
          // console.log('nextTag', nextTag)

          // the tag's offsetLeft after of nextTag
          const afterNextTagOffsetLeft = nextTag.offsetLeft + nextTag.offsetWidth + tagAndTagSpacing

          // the tag's offsetLeft before of prevTag
          const beforePrevTagOffsetLeft = prevTag.offsetLeft - tagAndTagSpacing

          if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
            $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth
          } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
            $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft
          }
        }
      }
    })

    return {
      scrollContainerRef,
      ...toRefs(data)
    }
  }
})
