/*
 * @Description: 成员管理-project
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  type_id: number
  name: string
  user_type1_name: string
  date_interval: Date[]
  status: number
  code: string
}
// 保存
interface saveQuery {
  id?: number
  name: string
  code: string
  type_id: number
  describe: string
  start_date: Date
  end_date: Date
  user_id_type1: number
  user_ids_type2: number[]
}
// 详情、删除
interface queryId {
  id: number
}
// 更改项目状态
interface changeStatusQuery {
  id: number
  status: number
}
// 显示、隐藏
interface changeShowQuery {
  id: number
  is_show: number
}
// 更改项目排序
interface sortQuery {
  ids: number[]
  sorts: number[]
}
interface exportQuery {
  project_id: number
}

/* 接口定义 */
export class projectApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/index',
      data
    })
  }

  // 保存
  static save (data: saveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/save',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/del',
      data
    })
  }

  // 更改项目状态
  static changeStatus (data: changeStatusQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/change_status',
      data
    })
  }

  // 显示、隐藏
  static changeShow (data: changeShowQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/change_show',
      data
    })
  }

  // 更改项目排序
  static changeSort (data: sortQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/change_sort',
      data
    })
  }

  // 导出
  static export (data: exportQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/handbook/export',
      data
    })
  }
}
