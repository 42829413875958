/* 成员管理 */
import { RouteRecordRaw } from 'vue-router'

import Layout from '@/layout/index.vue'
import EmptyLayout from '@/layout/empty.vue'

const route: RouteRecordRaw | any = {
  path: '/system',
  name: 'system',
  redirect: '/system/administrator',
  subset: true,
  component: Layout,
  meta: {
    id: 2,
    title: '成员管理',
    icon: 'members'
  },
  children: [
    {
      path: 'administrator',
      name: 'administrator',
      redirect: '/system/administrator',
      component: EmptyLayout,
      meta: {
        id: 3,
        parent_id: 2,
        title: '成员列表'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/system/administrator/index.vue'),
          meta: {
            id: 58,
            parent_id: 3,
            title: '成员列表',
            sidebar: false,
            breadcrumb: false
          }
        }
      ]
    },
    {
      path: 'position',
      name: 'position',
      redirect: '/system/position',
      component: EmptyLayout,
      meta: {
        id: 16,
        parent_id: 2,
        title: '岗位管理'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/system/position/index.vue'),
          meta: {
            id: 61,
            parent_id: 16,
            title: '岗位管理',
            sidebar: false,
            breadcrumb: false
          }
        }
      ]
    },
    {
      path: 'role',
      name: 'role',
      redirect: '/system/role',
      component: EmptyLayout,
      meta: {
        id: 10,
        parent_id: 2,
        title: '角色管理'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/system/role/index.vue'),
          meta: {
            id: 59,
            parent_id: 10,
            title: '角色管理',
            sidebar: false,
            breadcrumb: false
          }
        }
      ]
    },
    {
      path: 'adminLog',
      name: 'adminLog',
      redirect: '/system/adminLog',
      component: EmptyLayout,
      meta: {
        id: 15,
        parent_id: 2,
        title: '后台操作日志'
      },
      children: [
        {
          path: '',
          component: () => import('@/views/system/operationLog/adminLog.vue'),
          meta: {
            id: 60,
            parent_id: 15,
            title: '后台操作日志',
            sidebar: false,
            breadcrumb: false
          }
        }
      ]
    }
  ]
}

export default route
