import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c2751bb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-slot" }
const _hoisted_2 = { class: "image-slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_loading = _resolveComponent("loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createBlock(_component_el_image, {
    ref: "imgRef",
    class: _normalizeClass(_ctx.ifAnimation ? 'ifAnimation' : ''),
    src: _ctx.imgUrl,
    alt: _ctx.alt,
    fit: _ctx.fit,
    lazy: _ctx.lazy,
    style: _normalizeStyle(`width:${_ctx.width}px; height:${_ctx.height}px;`),
    "preview-src-list": _ctx.ifOnShow ? _ctx.srcListUrl : null,
    "z-index": _ctx.zIndex
  }, {
    error: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, { name: "image-load-fail" })
      ])
    ]),
    placeholder: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_loading)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["class", "src", "alt", "fit", "lazy", "style", "preview-src-list", "z-index"]))
}