import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _directive_el_select_loadmmore = _resolveDirective("el-select-loadmmore")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_select, _mergeProps({ "popper-class": "matterNameClass" }, _ctx.$props, {
    modelValue: _ctx.localVal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localVal) = $event)),
    clearable: "",
    filterable: "",
    remote: "",
    "remote-method": _ctx.remoteMethodFun,
    loading: _ctx.load,
    onChange: _ctx.changeFun,
    onVisibleChange: _ctx.visibleChangeFun,
    onRemoveTag: _ctx.removeTagFun,
    onClear: _ctx.clearFun,
    onBlur: _ctx.blurFun,
    onFocus: _ctx.focusFun
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: item[_ctx.objKey],
          label: item[_ctx.objLabel],
          value: item[_ctx.objKey],
          disabled: item.disabled
        }, null, 8, ["label", "value", "disabled"]))
      }), 128))
    ]),
    _: 1
  }, 16, ["modelValue", "remote-method", "loading", "onChange", "onVisibleChange", "onRemoveTag", "onClear", "onBlur", "onFocus"])), [
    [_directive_el_select_loadmmore, _ctx.handleLoadMore]
  ])
}