import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e5fa861"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-container" }
const _hoisted_2 = { class: "mask" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "el-upload__tip"
}
const _hoisted_8 = { style: {"display":"inline-block"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImgBox = _resolveComponent("ImgBox")!
  const _component_zoom_in = _resolveComponent("zoom-in")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_delete_filled = _resolveComponent("delete-filled")!
  const _component_arrow_left_bold = _resolveComponent("arrow-left-bold")!
  const _component_arrow_right_bold = _resolveComponent("arrow-right-bold")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.urlList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "images"
      }, [
        (index < _ctx.max)
          ? (_openBlock(), _createBlock(_component_ImgBox, {
              key: 0,
              ref_for: true,
              ref: "imgBoxRef",
              fit: "contain",
              src: item,
              width: _ctx.width,
              height: _ctx.height,
              ifAnimation: false,
              srcList: _ctx.urlList
            }, null, 8, ["src", "width", "height", "srcList"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              title: "预览",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onShowImg && _ctx.onShowImg(...args)))
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_zoom_in)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("span", {
              title: "移除",
              onClick: ($event: any) => (_ctx.remove(index))
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_delete_filled)
                ]),
                _: 1
              })
            ], 8, _hoisted_4),
            _withDirectives(_createElementVNode("span", {
              title: "左移",
              class: _normalizeClass({ disabled: index == 0 }),
              onClick: ($event: any) => (_ctx.move(index, 'left'))
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_left_bold)
                ]),
                _: 1
              })
            ], 10, _hoisted_5), [
              [_vShow, _ctx.urlList.length > 1]
            ]),
            _withDirectives(_createElementVNode("span", {
              title: "右移",
              class: _normalizeClass({ disabled: index == _ctx.urlList.length - 1 }),
              onClick: ($event: any) => (_ctx.move(index, 'right'))
            }, [
              _createVNode(_component_el_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_right_bold)
                ]),
                _: 1
              })
            ], 10, _hoisted_6), [
              [_vShow, _ctx.urlList.length > 1]
            ])
          ])
        ])
      ]))
    }), 128)),
    _withDirectives(_createVNode(_component_el_upload, {
      "show-file-list": false,
      action: _ctx.action,
      headers: _ctx.headers,
      accept: "image/*",
      "before-upload": _ctx.beforeUpload,
      "on-progress": _ctx.onProgress,
      "on-success": _ctx.onSuccess,
      "on-error": _ctx.onError,
      drag: "",
      class: "images-upload"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "image-slot",
          style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`)
        }, [
          (_ctx.placeholder)
            ? (_openBlock(), _createBlock(_component_el_image, {
                key: 0,
                src: _ctx.placeholder,
                fit: "cover",
                style: `width:100%;height:100%;`
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_plus)
            ]),
            _: 1
          })
        ], 4),
        _withDirectives(_createElementVNode("div", {
          class: "progress",
          style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`)
        }, [
          _createVNode(_component_el_image, {
            src: _ctx.progress.preview,
            style: _normalizeStyle(`width:${_ctx.width}px;height:${_ctx.height}px;`),
            fit: "fill"
          }, null, 8, ["src", "style"]),
          _createVNode(_component_el_progress, {
            type: "circle",
            width: Math.min(_ctx.width, _ctx.height) * 0.8,
            percentage: _ctx.progress.percent
          }, null, 8, ["width", "percentage"])
        ], 4), [
          [_vShow, _ctx.progress.percent]
        ])
      ]),
      _: 1
    }, 8, ["action", "headers", "before-upload", "on-progress", "on-success", "on-error"]), [
      [_vShow, _ctx.urlList.length < _ctx.max]
    ]),
    (!_ctx.notip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_alert, {
              title: 
            _ctx.notipTitle
              ? _ctx.notipTitle
              : `上传图片支持 ${_ctx.ext.join(
                  ' / '
                )} 格式，单张图片大小不超过 ${_ctx.size}MB，建议图片尺寸为 ${_ctx.width}*${_ctx.height}，且图片数量不超过 ${_ctx.max} 张`
          ,
              type: "info",
              "show-icon": "",
              closable: false
            }, null, 8, ["title"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}