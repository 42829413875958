
import { defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'FixedActionBar',
  setup () {
    onMounted(() => {
      data.onScroll()
      window.addEventListener('scroll', data.onScroll)
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', data.onScroll)
    })

    const data = reactive({
      isBottom: true,

      onScroll () {
        // 变量scrollTop是滚动条滚动时，滚动条上端距离顶部的距离
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        // 变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        // 变量scrollHeight是滚动条的总高度（当前可滚动的页面的总高度）
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // 滚动条到底部
        if (Math.ceil(scrollTop + windowHeight) >= scrollHeight) {
          data.isBottom = true
        } else {
          data.isBottom = false
        }
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
