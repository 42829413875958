
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'FileUpload',
  props: {
    // 上传文件大小限制，单位为MB
    size: {
      type: Number,
      default: 2
    },
    // 上传数量限制
    max: {
      type: Number,
      default: 3
    },
    // 上传的文件列表，例如：[{name: 'xxx.jpg', url: 'http://xxx.cdn.com/xxx.jpg'}]， 源
    files: {
      type: Array,
      default: () => []
    },
    // 是否隐藏提示栏
    notip: {
      type: Boolean,
      default: false
    },
    // 支持的文件类型
    ext: {
      type: Array,
      default: () => ['zip', 'rar', 'jpg']
    }
  },
  setup (props, { emit }) {
    const store = useStore()

    // onBeforeMount(() => {})

    const data: any = reactive({
      // 必选参数，上传的地址（根据接口）
      action: store.state.app.domainUrl + '/api/oss/upload',
      // 设置上传的请求头部
      headers: {
        token: store.state.user.token
      },

      // 上传前
      beforeUpload (file: any) {
        const fileName = file.name.split('.')
        const fileExt = fileName[fileName.length - 1]
        const isTypeOk: boolean = props.ext.indexOf(fileExt) >= 0
        const isSizeOk = file.size / 1024 / 1024 < props.size
        if (!isTypeOk) {
          ElMessage.error(`上传文件只支持 ${props.ext.join(' / ')} 格式！`)
        }
        if (!isSizeOk) {
          ElMessage.error(`上传文件大小不能超过 ${props.size}MB！`)
        }
        return isTypeOk && isSizeOk
      },

      // 文件上传超过限制
      onExceed () {
        ElMessage.warning('文件上传超过限制')
      },

      // 上传成功
      onSuccess (res: any, file: any) {
        // console.log('上传成功', res, file)

        // 新增数据
        const files = props.files
        files.push({
          name: file.name,
          url: res.res.url
        })

        // 父级数据更新
        emit('update:files', files)
        // 回调函数
        emit('getSuccess', res, file)

        ElMessage.success('上传成功')
      },

      // 上传失败
      onError () {
        ElMessage.error('上传失败')
      },

      // 删除文件
      onRemove (file: any, fileList: any) {
        // console.log('删除文件', file, fileList)

        if (file.status === 'ready') return
        const index = props.files.findIndex((item: any) => {
          return item === file
        })
        const files = props.files
        files.splice(index, 1)
        // 父级数据更新
        emit('update:files', files)

        ElMessage.success('删除成功')
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
