import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

/*
* 配置说明：
  // 是否有子集路由
  subset: true,

  // 导航配置项均放在路由的 meta 对象中
  meta: {
    // 用于后台权限匹配
    id: 66,
    // 用于后台权限匹配-父级id
    parent_id: 1,

    // 该路由在侧边栏导航和面包屑导航中展示的标题
    title: '新闻列表',
    // 侧边栏导航中显示的图标，图标使用 <svg-icon /> 组件展示
    icon: 'news',

    // 该路由是否在侧边栏导航中展示，如编辑页面 /edit/:id ，默认为 true
    sidebar: false,
    // 该路由是否在面包屑导航中展示，默认为 true
    breadcrumb: false,
    // 当路由设置了该属性，则会高亮相对应的侧边栏，一般会搭配 sidebar: false 一起使用
    // 例如从新闻列表进入新闻详情页，由于新闻详情页路由不在侧边栏导航中展示，这时你如果想高亮新闻列表的路由，就可以进行如下设置
    activeMenu: '/news/list',

    // 该路由是否在标签导航中展示，默认为 true
    tagView: false,
    // 固定标签，不可删除
    affix: true,

    // 右侧小标签，布尔值显示小红点，其他是什么就显示什么
    badge: 'new'
  }
*/

// 模块化路由
import project from './modules/project'
import system from './modules/system'

// 公共路由
const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: '登录',
      tagView: false
    }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/home.vue'),
        meta: {
          title: '控制台',
          affix: true
        }
      },
      {
        path: 'reload',
        name: 'reload',
        component: () => import('@/views/reload.vue'),
        meta: {
          tagView: false
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '404',
      tagView: false
    }
  }
]

// 顶级路由 (当 children 不为空的主导航只有一项时，则隐藏)
const asyncRoutes = [
  {
    meta: {
      id: 1,
      title: '考拉项目管理系统',
      icon: 'sidebar-default'
    },
    // 包含的路由
    children: [project, system]
  }
  // {
  //   meta: {
  //     title: '二级',
  //     icon: 'sidebar-default'
  //   },
  //   children: [ExTernalLinkExample]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes
})

// 重置路由
export function resetRouter (): void {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export { constantRoutes, asyncRoutes }
export default router
