
import { defineComponent, computed, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true
    }
  },
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const data: any = reactive({
      isExternal: computed(() => {
        return data.isExternalValidation(props.to)
      }),
      type: computed(() => {
        if (data.isExternal) {
          return 'a'
        }
        return 'router-link'
      }),

      isExternalValidation (path: any) {
        return /^(https?:|mailto:|tel:)/.test(path)
      },

      linkProps (to: any) {
        if (data.isExternal) {
          return {
            href: to,
            target: '_blank',
            rel: 'noopener'
          }
        }
        return {
          to: to
        }
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
