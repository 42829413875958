/*
 * @Description: 项目类型管理-typeManagement
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 列表
interface getListQuery {
  paginate: number
  page: number
  name: string
}
// 保存
interface saveQuery {
  id?: number
  name: string
}
// 详情、删除
interface queryId {
  id: number
}

/* 接口定义 */
export class typeManagementApi {
  // 列表
  static getList (data: getListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/type_index',
      data
    })
  }

  // 保存
  static save (data: saveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/type_save',
      data
    })
  }

  // 详情
  static getInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/type_info',
      data
    })
  }

  // 删除
  static del (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/admin/project/type_del',
      data
    })
  }
}
