import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c2a8178"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Item = _resolveComponent("Item")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_app_link = _resolveComponent("app-link")!
  const _component_SidebarItem = _resolveComponent("SidebarItem")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (_ctx.item.meta.sidebar !== false)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hasChildren)
          ? (_openBlock(), _createBlock(_component_app_link, {
              key: 0,
              to: _ctx.resolvePath(_ctx.item.path)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_menu_item, {
                  title: _ctx.item.meta.title,
                  index: _ctx.resolvePath(_ctx.item.path)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Item, {
                      icon: _ctx.item.meta.icon,
                      title: _ctx.item.meta.title,
                      badge: _ctx.item.meta.badge
                    }, null, 8, ["icon", "title", "badge"])
                  ]),
                  _: 1
                }, 8, ["title", "index"])
              ]),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createBlock(_component_el_sub_menu, {
              key: 1,
              title: _ctx.item.meta.title,
              index: _ctx.resolvePath(_ctx.item.path)
            }, {
              title: _withCtx(() => [
                (_ctx.item.meta)
                  ? (_openBlock(), _createBlock(_component_Item, {
                      key: 0,
                      icon: _ctx.item.meta.icon,
                      title: _ctx.item.meta.title,
                      badge: _ctx.item.meta.badge,
                      ifSubset: ""
                    }, null, 8, ["icon", "title", "badge"]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (route) => {
                  return (_openBlock(), _createBlock(_component_SidebarItem, {
                    key: route.path,
                    item: route,
                    "base-path": _ctx.resolvePath(_ctx.item.path)
                  }, null, 8, ["item", "base-path"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["title", "index"]))
      ]))
    : _createCommentVNode("", true)
}