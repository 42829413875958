
import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'Uplads',
  props: {
    // 文件数据
    fileList: {
      type: Array,
      default: () => []
    },
    // 限制文件大小(MB)
    requireSize: {
      type: Number,
      default: 5
    },
    // 上传最大个数
    maxCount: {
      type: Number,
      default: 9
    },
    // 是否支持多选文件(true支持)
    ifMultiple: {
      type: Boolean,
      default: true
    },
    // 是否只支持上传图片(true只能上传图片)
    ifImage: {
      type: Boolean,
      default: false
    },
    // 文件上传顺序(true新的再前面)
    reverse: {
      type: Boolean,
      default: true
    },
    // 是否支持列表排序(true支持)
    listSorting: {
      type: Boolean,
      default: true
    },
    // 是否有外部边框(true显示)
    ifBorder: {
      type: Boolean,
      default: true
    },
    // 组件宽度
    uploadWidth: {
      type: [Number, String],
      default: 600
    },
    // 组件最大高度
    uploadMaxHeight: {
      type: [Number, String],
      default: 600
    },
    // 是否显示提示提示文字/ true显示
    ifPromptWords: {
      type: Boolean,
      default: true
    },
    // 提示文字
    promptWords: {
      type: String,
      default: null
    }
  },
  setup (props, { emit }) {
    const store = useStore()

    // 获取全局变量
    const { proxy } = getCurrentInstance() as any

    const data: any = reactive({
      // 必选参数，上传的地址（根据接口）
      action: store.state.app.domainUrl + '/api/oss/upload',
      // 设置上传的请求头部
      headers: {
        token: store.state.user.token
      },
      // 管理按钮
      ifEdit: false,
      // 选中的文件
      selectedList: [],

      // 列表文件类型判断
      ifImageType (val: any) {
        // console.log(val)

        if (val.raw) {
          // 未上传
          return val.raw.type.split('/')[0] === 'image'
        } else {
          // 已上传
          const ifImage = proxy.$globalFun.getFileType(val.localUrl)
          return ifImage === 'image'
        }
      },

      // 判断是否选中
      ifSelected (val: any) {
        return data.selectedList.indexOf(val) !== -1
      },

      // 选中
      onSelected (e: any) {
        if (data.selectedList.indexOf(e) === -1) {
          data.selectedList.push(e)
        } else {
          const i = data.selectedList.findIndex((item: any) => {
            return e.localUrl === item.localUrl
          })
          data.selectedList.splice(i, 1)
        }
      },

      // 文件预览
      showFolder (url: string) {
        window.open(url)
      },

      // 上传文件之前的钩子
      beforeAvatarUpload (file: any) {
        // console.log('beforeAvatarUpload', file)
        const isLt2M = file.size / 1024 / 1024 < props.requireSize
        const type = file.type.split('/')
        const ext = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
        const isTypeOk = ext.indexOf(type[1]) >= 0

        if (!isLt2M) {
          ElMessage.warning(`上传文件大小不能超过 ${props.requireSize}MB!`)
          return false
        }
        if (props.ifImage && !isTypeOk) {
          ElMessage.warning(`上传图片只支持 ${ext.join(' / ')} 格式！`)
          return false
        }
      },

      // 最大限制
      handleExceed (files: any, fileList: any) {
        ElMessage.warning(`最多只支持 ${props.maxCount}个文件`)
      },

      // 上传成功
      handleAvatarSuccess (response: any, file: any, fileList: any) {
        // console.log('-handleAvatarSuccess-response', response)
        // console.log('handleAvatarSuccess-file', file)
        // console.log('handleAvatarSuccess-fileList', fileList)

        // 存储到数组中(根据接口返回的信息改)
        if (response.code === -1) {
          return ElMessage.error('上传失败')
        }

        const newObj = {
          localUrl: response.res.url,
          name: file.name,
          status: 'success'
        }

        const list = props.fileList
        if (props.reverse) {
          list.unshift(newObj)
        } else {
          // 倒序
          list.push(newObj)
        }

        // 父级数据更新
        emit('update:fileList', list)
        ElMessage.success('上传成功')
      },
      // 上传失败
      onError (error: any) {
        console.error('error', error)
        ElMessage.error('上传失败')
      },

      // 上移
      upGo (index: number) {
        const list = props.fileList
        list[index] = list.splice(index - 1, 1, list[index])[0]

        // 父级数据更新
        emit('update:fileList', list)
      },

      // 下移
      downGo (index: number) {
        const list = props.fileList
        list[index] = list.splice(index + 1, 1, list[index])[0]

        // 父级数据更新
        emit('update:fileList', list)
      },

      // 取消管理
      cancelEdit () {
        data.ifEdit = false
        // 清空选中
        data.selectedList = []
      },

      // 删除
      async del (index: number) {
        // 模态框
        const confirmResult = await ElMessageBox.confirm('是否删除文件？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => err)
        // 取消
        if (confirmResult !== 'confirm') return

        if (index !== undefined) {
          const list = props.fileList
          list.splice(index, 1)
          data.selectedList.splice(index, 1)

          // 父级数据更新
          emit('update:fileList', list)
        } else {
          // 批量删除
          let list = props.fileList
          list = list.filter((x: any) => !data.selectedList.find((item: any) => x.localUrl === item.localUrl))
          // 父级数据更新
          emit('update:fileList', list)
          data.cancelEdit()
        }

        ElMessage.success('删除成功！')
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
