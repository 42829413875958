/*
 * @Description: 存放全局公用状态
 */
interface stateType {
  [propName: string]: any
}

const state = {
  // 是否开启路由权限功能
  openPermission: true,
  // 侧边栏是否收起
  sidebarCollapse: false,
  // 是否显示进度条
  enableProgress: true,
  // 是否开启控制台
  enableDashboard: false
}

const getters = {}

const actions = {}

const mutations = {
  // 切换侧边栏导航展开/收起
  toggleSidebarCollapse (state: stateType): void {
    state.sidebarCollapse = !state.sidebarCollapse
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
