/*
 * @Description: 挂载路由导航守卫
 */
import router, { asyncRoutes, resetRouter } from './router'
import { ElMessageBox } from 'element-plus'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { useStore } from './store'
const store = useStore()

// progress样式
NProgress.configure({ easing: 'ease', showSpinner: false })

router.beforeEach(async (to, from, next) => {
  store.state.settings.enableProgress && NProgress.start()

  // 权限判断
  if (store.state.user.token) {
    if (store.state.menu.isGenerate) {
      /* 权限已载入 */
      if (store.state.menu.routes.length === 0) {
        /* 登录后后台没有给它配置权限 */
        throw ElMessageBox.alert('您没有相关权限，请联系管理员', '提示', {
          showClose: false
        }).then(() => {
          // 强制登出
          store.dispatch('user/logout', 'server')
          return false
        })
      } else {
        /* 登录后有权限 */
        // 根据路由判断属于哪个头部导航
        if (store.state.menu.isGenerate) {
          store.commit('menu/setHeaderActived', to.path)
        }

        if (to.path === '/dashboard') {
          // 如果未开启控制台页面，则默认进入侧边栏导航第一个模块
          if (!store.state.settings.enableDashboard) {
            // 判断第一个是有子集的还是没有
            const route = store.getters['menu/sidebarRoutes'][0]
            const path = route.subset ? route.children[0].redirect : route.path
            next({
              path,
              replace: true
            })
          } else {
            next()
          }
        } else {
          next()
        }
      }
    } else {
      /* 权限未载入 */
      // 重置路由
      resetRouter()

      // 动态新增路由
      const accessRoutes = await store.dispatch('menu/generateRoutes', {
        asyncRoutes,
        currentPath: to.path
      })
      accessRoutes.forEach((item: any) => {
        router.addRoute(item)
      })
      router.addRoute({ path: '/:catchAll(.*)', redirect: '/404' })

      next({ ...to, replace: true })
    }
  } else {
    /* 未登录 */
    if (to.path !== '/login') {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  store.state.settings.enableProgress && NProgress.done()
})
