/*
 * @Description: 负责实现用户的登录、登出，以及用户信息处理。
 */

import { userApi } from '@/api/modules/user'
import Router from '@/router'

interface stateType {
  info: any
  token: string | null
}
const state = {
  // 用户信息
  info: localStorage.info,
  // token
  token: localStorage.token
}

const getters = {}

const actions = {
  // 登录
  login ({ commit }: any, data: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      try {
        const { res } = await userApi.login(data)
        console.log('登录成功', res)

        commit('setUserData', res)
        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },

  // 登出(清除数据)
  async logout ({ commit }: any, data: any) {
    if (data) {
      // console.log('接口')
      await userApi.logout({})
    }

    commit('removeUserData')
    commit('menu/invalidRoutes', null, { root: true })
    commit('TagsView/Empty', null, { root: true })

    Router.push({
      path: '/login'
    })
  },

  // 获取我的权限
  getPermissions ({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const { res } = await userApi.getRole({})
      console.log('获取我的权限', res.role)
      resolve(res.role)
    })
  }
}

const mutations = {
  setUserData (state: stateType, data: any): void {
    localStorage.setItem('info', JSON.stringify(data.user))
    localStorage.setItem('token', data.token)
    state.info = data.user
    state.token = data.token
  },

  removeUserData (state: stateType): void {
    localStorage.removeItem('info')
    localStorage.removeItem('token')
    state.info = ''
    state.token = ''
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
