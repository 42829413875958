/*
 * @Description: 用户相关-user
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 登录
interface loginQuery {
  username: string
  password: string
}
// 后台操作日志列表
interface getUserLogListQuery {
  paginate: number
  page: number
  username: string
  log_info: string
}
// 无参数
interface noParameter {
  [key: string]: any
}

/* 接口定义 */
export class userApi {
  // 登录
  static login (data: loginQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/account/login',
      data
    })
  }

  // 登出
  static logout (data: noParameter): Promise<HttpResponse> {
    return http({
      url: '/api/admin/account/logout',
      data
    })
  }

  // 获取权限
  static getRole (data: noParameter): Promise<HttpResponse> {
    return http({
      url: '/api/admin/account/role',
      data
    })
  }

  // 后台操作日志列表
  static getUserLogList (data: getUserLogListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/admin/log/user_index',
      data
    })
  }
}
