import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46608597"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topbar-container" }
const _hoisted_2 = { class: "left-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!
  const _component_UserMenu = _resolveComponent("UserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({
          'sidebar-collapse': true,
          'is-collapse': _ctx.$store.state.settings.sidebarCollapse
        }),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('settings/toggleSidebarCollapse')))
      }, [
        _createVNode(_component_svg_icon, { name: "collapse" })
      ], 2),
      _createVNode(_component_el_breadcrumb, { "separator-class": "ArrowRight" }, {
        default: _withCtx(() => [
          _createVNode(_TransitionGroup, { name: "breadcrumb" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbList, (item) => {
                return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                  key: item.path
                }, {
                  default: _withCtx(() => [
                    (typeof item.title === 'string')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(item.title[_ctx.$route.query.type] || '-'), 1)
                        ], 64))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_UserMenu)
  ]))
}