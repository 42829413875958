
import { computed, defineComponent, onBeforeMount, reactive, toRefs, watch, nextTick, ref, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import ScrollPane from './ScrollPane.vue'

export default defineComponent({
  components: { ScrollPane },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onBeforeMount(() => {
      // 第一次进入页面时，修改tag的值
      data.setTags(route)
    })

    const scrollPane: any = ref()
    const tagBoxRef: any = ref()

    const data: any = reactive({
      top: 0,
      left: 0,
      selectedTag: {},

      // 存储标签
      tagsList: computed((): any => {
        return store.state.TagsView.tagsList
      }),

      // 路由路径 和 当前url/设定的父级路由 相等时高亮
      isActive (item: any) {
        // 不显示标签导航时，判断和他的父级是否一致
        if (route.meta.tagView === false) {
          return item.path === route.meta.activeMenu
        } else {
          return item.path === route.path
        }
      },

      // 设置标签
      setTags (route: any) {
        if (route.meta.tagView === false) return
        const isExist = data.tagsList.some((item: any) => {
          return item.path === route.path
        })
        // console.log('isExist', isExist)

        if (!isExist) {
          // 没有新增
          const newObj = {
            name: route.name,
            meta: route.meta,
            title: route.meta.title,
            path: route.path,
            fullPath: route.fullPath,
            query: route.query
          }
          // console.log('newObj', newObj)
          store.commit('TagsView/SET_Tags', newObj)
        } else {
          // 相同但是参数不一样覆盖
          const index = data.tagsList.findIndex((i: any) => {
            return i.path === route.path && i.fullPath !== route.fullPath
          })
          if (index !== -1) {
            store.commit('TagsView/PUT_Tags', { index, route })
          }
        }
      },

      // 标签定位
      moveToCurrentTag () {
        nextTick(() => {
          // console.log('route', route)
          if (route.meta.tagView === false) return
          // console.log('itemRef', itemRef.value)
          scrollPane.value.moveToTarget(route)
        })
      },

      // 固定标签，不可删除
      isAffix (tag: any) {
        return tag.meta && tag.meta.affix
      },

      // 关闭单个标签
      async closeTags (item: any) {
        // console.log('item', item)

        // 要删除的下标
        const index = data.tagsList.map((e: any) => e).indexOf(item)
        // console.log('index', index)

        // 当前页面
        const delItem = await store.dispatch('TagsView/DEL_Tags_A', index)
        // console.log('delItem', delItem)

        // 上个页面
        const tags = data.tagsList[index] ? data.tagsList[index] : data.tagsList[index - 1]

        if (tags) {
          // 跳转回上一个页面
          delItem.fullPath === route.fullPath && router.push(tags.fullPath)
        } else {
          // 没有标签了，跳转去控制台
          router.push('/dashboard')
        }
      },

      // 右侧下拉按钮
      handleTags (command: string) {
        if (command === 'other') {
          /* 关闭其他标签 */
          const curItem = data.tagsList.filter((item: any) => {
            return item.fullPath === route.fullPath
          })
          store.commit('TagsView/ASSIGNMENT_Tags', curItem)
        } else {
          /* 关闭全部标签 */
          store.commit('TagsView/ASSIGNMENT_Tags', [])
          router.push('/dashboard')
        }
      }
    })

    // 对router进行监听，每当访问router时，对tags的进行修改
    watch(
      () => route.path,
      () => {
        data.setTags(route)
        data.moveToCurrentTag()
      }
    )

    // 注入给子组件
    provide('tagBoxRef', computed(() => tagBoxRef.value))

    return {
      scrollPane,
      tagBoxRef,
      ...toRefs(data)
    }
  }
})
